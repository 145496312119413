import { MiniMap as DefaultMiniMap } from 'reactflow';
import { useMenuStore } from '../../stores/menu';

export interface options {
  pannable: boolean;
  zoomable: boolean;
  nodeColor: string;
  style: React.CSSProperties;
}

function MiniMap() {
  const options: options = {
    pannable: true,
    zoomable: true,
    nodeColor: '#8250df',
    style: {
      background: '#fff',
      position: 'absolute',
      maxWidth: '50%',
      borderRadius: 8,
      height: 160,
      opacity: 1,
      visibility: 'hidden',
      transition: 'visibility 0s 1s, opacity 1s linear',
      boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'
    }
  }

  const minimap = useMenuStore((state) => state.minimap)

  if (minimap) {
    options.style.opacity = '1';
    options.style.visibility = 'visible';
    options.style.transition = 'opacity 0.5s linear';
  } else {
    options.style.opacity = '0';
    options.style.visibility = 'hidden';
    options.style.transition = 'visibility 0s 0.5s, opacity 0.5s linear';
  }

  return (
    <DefaultMiniMap {...options} />
  );
};

export default MiniMap;
