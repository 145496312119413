import { ReactFlowProvider } from 'reactflow'
import Flow from './components/Flow'
import ToolBar from './components/ToolBar'
import MiniMap from './components/MiniMap'
import ZoomBar from './components/ZoomBar'
import Background from './components/Background'
import 'reactflow/dist/base.css';
import './index.css';

const App = () => {

    // Prevent zooming outside of canvas
  document.addEventListener("wheel", e => {
    e.preventDefault();
  }, { passive: false });
  
  return (
    <ReactFlowProvider >
      <div className="h-screen flex">
        <Flow />
        <ToolBar />
        <ZoomBar />
        <MiniMap />
        <Background />
      </div>
    </ReactFlowProvider >
  );
};

export default App;
