import _lodash from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import {
  Connection,
  EdgeChange,
  NodeChange,
  addEdge,
  applyNodeChanges,
  OnSelectionChangeParams,
  applyEdgeChanges,
} from 'reactflow';
import { NodeState } from './types';
import { temporal } from 'zundo';

const defaultNodes = [
  { id: '1', type: 'special', data: { label: 'Node 1' }, position: { x: 600, y: 100 } },
  { id: '2', type: 'special', data: { label: 'Node 2' }, position: { x: 300, y: 300 } },
  { id: '3', type: 'special', data: { label: 'Node 3' }, position: { x: 900, y: 300 } },
];

const defaultEdges = [
  { id: 'e1-2', source: 'node-1', target: 'node-2' },
  { id: 'e1-3', source: 'node-1', target: 'node-3' },
]

const useNodeStore = create<NodeState>()(
  devtools(
    persist(
      temporal(
        (set, get) => ({
          nodes: defaultNodes,
          edges: defaultEdges,
          selected: [],
          onNodesChange: (changes: NodeChange[]) => {
            set({
              nodes: applyNodeChanges(changes, get().nodes),
            });
          },
          onEdgesChange: (changes: EdgeChange[]) => {
            set({
              edges: applyEdgeChanges(changes, get().edges),
            });
          },
          onConnect: (connection: Connection) => {
            set({
              edges: addEdge(connection, get().edges),
            });
          },
          onSelectionChange: (params: OnSelectionChangeParams) => {
            const selection = params.nodes.filter((node) => node.selected === true);
            set({
              selected: selection.map((node) => node.id),
            });
          },
          addNode: () => {
            const defaultNode = {
              id: uuidv4(),
              type: 'special',
              data: { label: 'Example' },
              position: {
                x: 0,
                y: 0,
              },
            };
            set({
              nodes: [...get().nodes, defaultNode],
            });
          },
          updateNode(id: string, text: string) {
            set({
              nodes: get().nodes.map((node) => {
                if (node.id === id) {
                  return { ...node, data: { ...node.data, text } };
                }
                return node;
              }),
            });
          },
          deleteNode() {
            set({
              nodes: get().nodes.filter((node) => !get().selected.includes(node.id)),
            });
          },
          deleteNodes: () => {
            set({
              nodes: [],
              edges: [],
            });
          },
          setDefaultNodes: () => {
            set({
              nodes: defaultNodes,
              edges: defaultEdges,
            });
          },
        }),
        {
          handleSet: (handleSet) =>
            _lodash.throttle<typeof handleSet>((state: Partial<NodeState>) => {
              handleSet(state);
            }, 1000),
        },
      ),
      {
        name: 'node-storage',
      },
    )
  )
);

export { useNodeStore };
