import { useEffect, useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import {
  ReactFlow,
  useViewport,
  SelectionMode,
  ConnectionMode,
  ConnectionLineType,
} from 'reactflow';
import CustomNode from '../Node';
import { useNodeStore } from '../../stores/node';
import { useMenuStore } from '../../stores/menu';
import { MenuState, NodeState } from '../../stores/types';

const nodeSelector = (state: NodeState) => ({
  nodes: state.nodes,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  onSelectionChange: state.onSelectionChange,
  onConnect: state.onConnect,
});

const menuSelector = (state: MenuState) => ({
  proOptions: state.proOptions,
  fitViewOptions: state.fitViewOptions,
  defaultViewport: state.defaultViewport,
  panOnDrag: state.panOnDrag,
  selectionOnDrag: state.selectionOnDrag,
  nodesConnectable: state.nodesConnectable,
  nodesDraggable: state.nodesDraggable,
  nodesFocusable: state.nodesFocusable,
  edgesFocusable: state.edgesFocusable,
  edgesUpdatable: state.edgesUpdatable,
  elementsSelectable: state.elementsSelectable,
});

const Flow = () => {

  const nodeTypes = useMemo(() => ({
    special: CustomNode
  }), []);

  const {
    nodes,
    edges,
    onConnect,
    onNodesChange,
    onEdgesChange, 
    onSelectionChange,
  } = useNodeStore(nodeSelector, shallow);

  const {
    panOnDrag,
    selectionOnDrag,
    proOptions,
    fitViewOptions,
    // defaultViewport,
    nodesConnectable,
    nodesDraggable,
    nodesFocusable,
    edgesFocusable,
    edgesUpdatable,
    elementsSelectable,
  } = useMenuStore(menuSelector, shallow);

  const { x, y, zoom } = useViewport();
  useEffect(() => {
    console.log(x, y, zoom);
  }, [x, y, zoom]);

  // useOnViewportChange({
  //   onStart: useCallback((viewport: Viewport) => console.log('start', viewport), []),
  //   onChange: useCallback((viewport: Viewport) => console.log('change', viewport), []),
  //   onEnd: useCallback((viewport: Viewport) => console.log('end', viewport), []),
  // });
  
  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onConnect={onConnect}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onSelectionChange={onSelectionChange}
      nodeTypes={nodeTypes}
      proOptions={proOptions}
      nodesConnectable={nodesConnectable}
      nodesDraggable={nodesDraggable}
      nodesFocusable={nodesFocusable}
      edgesFocusable={edgesFocusable}
      edgesUpdatable={edgesUpdatable}
      elementsSelectable={elementsSelectable}
      nodeOrigin={[0.5, 0.5]}
      minZoom={0.25}
      maxZoom={4.0}
      // defaultViewport={defaultViewport}
      fitViewOptions={fitViewOptions}
      fitView={true}
      connectionMode={ConnectionMode.Loose}
      connectionLineType={ConnectionLineType.SmoothStep}
      snapToGrid={true}
      snapGrid={[25, 25]}
      panOnScroll={false}
      zoomOnScroll={true}
      panOnDrag={panOnDrag}
      selectionOnDrag={selectionOnDrag}
      selectionMode={SelectionMode.Partial}
    />
  );
};

export default Flow;
