import { useReactFlow, useViewport } from "reactflow";
import {
  ZoomInIcon,
  ZoomOutIcon
} from "../Icon";

const ZoomBar = () => {
  const { zoomIn, zoomOut } = useReactFlow();
  const { zoom,  } = useViewport();
  return (
    <div className="absolute bottom-5 left-5">
      <div className="grid grid-rows-1 grid-flow-col bg-white rounded-lg shadow border">
        <button onClick={() => zoomOut()} className="flex justify-center items-center p-2 text-gray-500 hover:bg-gray-100 active:bg-purple-200 active:text-purple-700 focus:outline-none h-9 w-9 xl:h-12 xl:w-12">
          <span className="justify-center items-center">
            <ZoomOutIcon />
          </span>
        </button>
        <button className="flex justify-center items-center p-2 text-[9px] md:text-xs font-small text-gray-500 hover:bg-gray-100 active:bg-purple-200 active:text-purple-700 focus:outline-none h-9 w-9 xl:h-12 xl:w-12">
          <span className="justify-center items-center text-xs">
            {Math.ceil(zoom * 100) + "%"}
          </span>
        </button>
        <button onClick={() => zoomIn()} className="flex justify-center items-center p-2 text-gray-500 hover:bg-gray-100 active:bg-purple-200 active:text-purple-700 focus:outline-none h-9 w-9 xl:h-12 xl:w-12">
          <span className="justify-center items-center">
            <ZoomInIcon />
          </span>
        </button>
      </div>
    </div>
	);
};

export default ZoomBar;
