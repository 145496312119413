import { create } from 'zustand'
import { devtools, persist, createJSONStorage } from 'zustand/middleware'
import { BackgroundVariant } from 'reactflow';
import { MenuState } from './types';

const defaultViewport = {
  x: 0,
  y: 0,
  zoom: 0.1
}

export const useMenuStore = create<MenuState>()(
  devtools(
    persist(
      (set, get) => ({
        open: true,
        locked: false,
        minimap: false,
        panOnDrag: false,
        selectionOnDrag: false,
        nodesConnectable: true,
        nodesDraggable: true,
        nodesFocusable: true,
        edgesFocusable: true,
        edgesUpdatable: true,
        elementsSelectable: true,
        backgroundVariant: BackgroundVariant.Dots,
        proOptions: { hideAttribution: true },
        viewport: defaultViewport,
        fitViewOptions: {
          padding: 0.1,
          minZoom: 0.1,
          maxZoom: 4.0,
          duration: 500,
        },
        toggleMenu: () => set((state) => ({
          open: !state.open
        })),
        toggleMinimap: () => set((state) => ({
          minimap: !state.minimap
        })),
        togglePan: () => set((state) => ({
          panOnDrag: !state.panOnDrag
        })),
        toggleSelection: () => set((state) => ({
          selectionOnDrag: !state.selectionOnDrag
        })),
        toggleLock: () => set((state) => ({
          locked : !state.locked,
          nodesConnectable: state.locked,
          nodesDraggable: state.locked,
          nodesFocusable: state.locked,
          edgesFocusable: state.locked,
          edgesUpdatable: state.locked,
          elementsSelectable: state.locked,
        })),
        toggleBackground: () => {
          const length = Object.keys(BackgroundVariant).length;
          const count = Object.values(BackgroundVariant).indexOf(get().backgroundVariant);
          const next = Object.values(BackgroundVariant)[(count + 1) % length];
          set({ backgroundVariant: next})
        },
      }),
      {
        name: 'menu-storage',
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);

export default useMenuStore;
