import { BackgroundVariant, useReactFlow } from "reactflow";
import useMenuStore from "../../stores/menu";
import { useNodeStore } from "../../stores/node";
import ToolBarButton from "../Button";
import {
  LockIcon,
  SelectIcon,
  PanIcon,
  AddNodeIcon,
  DeleteNodeIcon,
  UndoIcon,
  RedoIcon,
  MiniMapIcon,
  BackgroundIcon,
  FitViewIcon,
  DeleteDiagramIcon,
  DefaultDiagramIcon,
} from "../Icon";


const ToolBar = () => {
  const {
    locked,
    minimap,
    panOnDrag,
    selectionOnDrag,
    backgroundVariant,
    toggleMinimap,
    toggleBackground,
    togglePan,
    toggleSelection,
    toggleLock
  } = useMenuStore();
  const {
    addNode,
    deleteNode,
    deleteNodes,
    setDefaultNodes
  } = useNodeStore((state) => state);
  const {
    undo,
    redo,
  } = useNodeStore.temporal.getState();

  const reactFlowInstance = useReactFlow();

  const push = () => {
    reactFlowInstance.fitView({ padding: 0.2 });
  };

  return (
    <div className="absolute max-w-[90%] mt-3 left-1/2 transform -translate-x-1/2">
      <div className="grid grid-rows-1 grid-flow-col gap-1 lg:gap-2 bg-white rounded-lg shadow p-1 lg:p-2 overflow-x-auto border">
        <ToolBarButton
          isActive={locked}
          action={toggleLock}
          icon={LockIcon} />
        <div className="border border-gray-200" />
        <ToolBarButton
          isActive={selectionOnDrag}
          action={toggleSelection}
          icon={SelectIcon} />
        <ToolBarButton
          isActive={panOnDrag}
          action={togglePan}
          icon={PanIcon} />
        <ToolBarButton
          action={addNode}
          icon={AddNodeIcon} />
        <ToolBarButton
          action={deleteNode}
          icon={DeleteNodeIcon} />
        <ToolBarButton
          action={undo}
          icon={UndoIcon} />
        <ToolBarButton
          action={redo}
          icon={RedoIcon} />
        <ToolBarButton
          action={push}
          icon={FitViewIcon} />
        <ToolBarButton
          isActive={minimap}
          action={toggleMinimap}
          icon={MiniMapIcon} />
        <ToolBarButton
          isActive={backgroundVariant !== BackgroundVariant.Dots}
          action={toggleBackground}
          icon={BackgroundIcon} />
        <ToolBarButton
          action={deleteNodes}
          icon={DeleteDiagramIcon} />
        <ToolBarButton
          action={setDefaultNodes}
          icon={DefaultDiagramIcon} />
      </div>
    </div>
	);
};

export default ToolBar;
