import React from 'react';
import { Background as DefaultBackground } from 'reactflow';
import { useMenuStore } from '../../stores/menu';
// import { BackgroundVariant } from '../../stores/types';
import { BackgroundVariant } from 'reactflow';

export interface Options {
  className?: string;
  variant?: BackgroundVariant;
  gap: number;
  dragable: boolean;
  selectable: boolean;
  style: React.CSSProperties;
}

const Background = () => {
  const variant = useMenuStore((state) => state.backgroundVariant);
  const options: Options = {
    className: 'bg-white',
    gap: 25,
    variant: variant,
    dragable: true,
    selectable: true,
    style: {
      zIndex: -1,
      cursor: 'grab'
    }
  };
    
  return (
    <DefaultBackground {...options} />
  );
};

export default Background;
