import { FC } from 'react';
import { NodeProps, Position, Handle } from 'reactflow';
import { NodeData } from '../../stores/types';

const handleClassName = "h-4 w-4 rounded-full shadow bg-gray-200 border border-gray-300 cursor-pointer"

const CustomNode: FC<NodeProps<NodeData>> = ({ data, id, selected }) => {
  // const { updateNode } = useNodeStore();
  // const onChange = useCallback(
  //   (evt: ChangeEvent<HTMLInputElement>) => {
  //     updateNode(id, evt.target.value);
  //   },
  //   [id, updateNode]
  // );

  return (
    <div className="w-64 h-20 bg-white shadow rounded-lg">
      <Handle
        id={`${id}_top`}
        type="source"
        position={Position.Top}
        className={`${handleClassName} -mt-1`}
      />
      <Handle
        id={`${id}_left`}
        type="source"
        position={Position.Left}
        className={`${handleClassName} -ml-1`}
      />
      <Handle
        id={`${id}_right`}
        type="source"
        position={Position.Right}
        className={`${handleClassName} -mr-1`}
      />
      <Handle
        id={`${id}_bottom`}
        type="source"
        position={Position.Bottom}
        className={`${handleClassName} -mb-1`}
      />
    </div>
  );
};

export default CustomNode;
