// import React, { useState } from 'react';
// import { usePopper } from 'react-popper';

interface Options {
  open?: boolean;
  title?: string;
  icon: any;
  new?: boolean;
  isActive?: boolean;
  action?: () => void;
}

// export const MenuButton = (props: Options) => {
//   const [showTooltip, setShowTooltip] = useState(false)
//   const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
//   const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
//   const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
//   const { styles, attributes } = usePopper(referenceElement, popperElement, {
//     placement: 'right',
//     strategy: 'fixed',
//     modifiers: [
//       { name: 'offset', options: { offset: [0, 8] } },
//       { name: 'arrow', options: { element: arrowElement } },
//       { name: 'preventOverflow', enabled: true }],
//   });

//   return (
//     <li>
//       <button onClick={props.action} ref={setReferenceElement} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}
//         className="w-full relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-purple-500 pr-4">
//         <span className="inline-flex justify-center items-center ml-4">
//           <props.icon />
//         </span>
//         <span className={`${props.open ? "opacity-100" : "opacity-0"} ml-4 text-sm tracking-wide truncate transition-opacity linear duration-500`}>{props.title}</span>
//         <span className={`${props.open ? "opacity-100" : "opacity-0"} ${props.new ? "" : "hidden"} px-2 py-0.5 ml-auto text-xs font-light tracking-wide text-purple-500 bg-purple-50 rounded-full transition-opacity linear duration-500`}>New</span>
//       </button>
//       {showTooltip && !props.open && (
//         <div ref={setPopperElement} className={`${showTooltip ? "opacity-100" : "opacity-0"} transition-opacity duration-1000`} id="tooltip" style={styles.popper} {...attributes.popper}>
//           <span>{props.title}</span>
//           <div ref={setArrowElement} id="arrow" style={styles.arrow} />
//         </div>
//       )}
//     </li>
// 	);
// };

const ToolBarButton = (props: Options) => {  
  return (
    <button onClick={props.action} className={`${props.isActive ? "bg-purple-200 text-purple-700" : "hover:text-gray-500 hover:bg-gray-100"} flex justify-center items-center p-2 text-xs font-medium rounded-lg active:bg-purple-200 active:text-purple-700 focus:outline-none h-8 w-8 md:h-10 md:w-10 xl:h-12 xl:w-12`}>
      <span className="justify-center items-center">
        <props.icon />
      </span>
    </button>
	);
};

export default ToolBarButton;
